<template>
  <nav class="navbar navbar-expand-sm bg-body navbar-light sticky-top">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="collapsibleNavbar">
        <ul class="navbar-nav">
          <template v-if="loggedIn">
            <li class="nav-item">
              <a class="btn btn-danger" @click.prevent="logout" style="margin-left: 1rem">خروج</a>
            </li>
          </template>
          <template v-else>
            <li class="nav-item">
              <router-link to="/request-login"  class="btn btn-login" style="display: block">ورود/‌ثبت‌نام
              </router-link>
            </li>
          </template>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'BecomeMentor'}"><p
                style="color: rgb(3, 4, 94); vertical-align: center; display: inline">سنیور هستید؟</p></router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'ExpertsList'}"><p
                style="color: rgb(3, 4, 94); vertical-align: middle; display: inline">در جستجوی سنیور هستید؟</p>
            </router-link>
          </li>
        </ul>
      </div>
      <a class="navbar-brand" href="/">
        <img width="51.2" height="51.2" src="@/assets/img/seniora.png" alt="seniora.online">
      </a>

    </div>
  </nav>
  <hr class="separator">
</template>

<script setup>
import {storeToRefs} from "pinia";
import {useAuthStore} from "@/store/user";

const {loggedIn} = storeToRefs(useAuthStore());
const {logout} = useAuthStore();


</script>

<style scoped>
.separator {
  margin: 0;
  opacity: 0.1;
}

.btn {
  width: 120px;
  margin-right: 10px;
}

.btn-login {
  background-color: #01a2f5;
  color: white;
  border-radius: 25em;
  margin-left: 1rem;
}

.btn-login:hover {
  background-color: #026DA6FF;
  color: white;
}

.btn-register {
  border: solid thin #01a2f5;
  color: #01a2f5;
}

.btn-register:hover {
  background-color: #01a2f5;
  color: white;
}

@media (max-width: 575px) {
  .btn {
    margin-right: 0 !important;
    margin-top: 7px !important;
  }
}
</style>