import { defineStore } from "pinia";
import http from "@/api/api.service";
import { ref } from "vue";

const APIPREFIX = "auth";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    token: ref(localStorage.getItem("token") || null),
    loggedIn: ref(!!localStorage.getItem("token")),
    mobileNumber: ref(null),
    countryCode: ref(null),
    user: ref(null),
    hasName: ref(false),
  }),

  getters: {},

  actions: {
    async requestLogin(username, countryCode) {
      return (
        await http.post(`${APIPREFIX}/request-login/`, {
          mobile_number: username,
          country: countryCode,
        })
      ).data;
    },

    async Login(username, password) {
      const response = (
        await http.post(`${APIPREFIX}/login/`, {
          mobile_number: username,
          password: password,
          country: this.$state.countryCode,
        })
      ).data;
      if (response) {
        this.token = response.token;
        this.hasName = response.has_name;
        this.loggedIn = true;
        localStorage.setItem("token", this.token);
        localStorage.setItem("hasName", response.has_name);
        http.defaults.headers.common["Authorization"] = this.token;
      }

      return response;
    },

    async logout() {
      await http.get(`${APIPREFIX}/logout/`).finally(() => {
        this.loggedIn = false;
        localStorage.removeItem("token");
        localStorage.removeItem("hasName");
      });
    },

    async updateBasicProfile(firstname, lastname, email) {
      const data = {
        first_name: firstname,
        last_name: lastname,
        email: email,
      };
      this.user = (await http.put(`${APIPREFIX}/basic-profile/`, data)).data;
    },

    async fetchBasicProfile() {
      http
        .get(`${APIPREFIX}/basic-profile/`)
        .then((res) => {
          this.user = res.data;
        })
        .catch((e) => {
          if (e.response.status == 403) {
            this.logout();
          }
        });
    },
  },
});
